.container-pdf{
    page-break-inside: avoid;
    break-inside: avoid;
}

.box-pdf{
    margin-top: 10px;
    // page-break-inside: avoid;
    // break-inside: avoid;
}

.category-pdf-txt{
    text-decoration: underline;
    // page-break-inside: avoid;
    // break-inside: avoid;
}

.box-indicator-txt{
    margin-left: 1em;
    // page-break-inside: avoid;
    // break-inside: avoid;
}

.editor-print{
    page-break-inside: avoid;
    break-inside: avoid;
    display: inline;
}

@media print{
    *{
        -webkit-print-color-adjust: exact; 
    }
    // div {
    //     page-break-inside:avoid;
    //     break-inside: avoid;
    // }
}

.boxdesign{
    background-color: #f2f3f8;
    border-radius: 4px;
    border: 1px solid #DDDDDD;
    // page-break-inside:avoid;
    // break-inside: avoid;
}

.header-styl-pdf{
    margin-bottom: 0;
    margin-top: 0;
    border-bottom: 1px solid #DDDDDD;
    padding: 15px 15px;
//     page-break-inside:avoid;
//     break-inside: avoid;
}

.contain-styl-pdf{
    padding: 15px 15px;
    // page-break-inside:avoid;
    // break-inside: avoid;
}